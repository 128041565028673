import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import { getAdminCommision, Lockplandata } from "../../actions/admin";

import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";
import { Link, useHistory } from "react-router-dom";
import CardHeader from "../../components/Card/CardHeader.js";
import * as moment from "moment";

const rows = [
  // getData('')
  //   createData('firstName', 'lastName', 'Email', 'phoneNo', 'accountType')
  //   createData('Donut', 452, 25.0, 51, 4.9),
];

const headCells = [
  { id: "category", numeric: false, disablePadding: true, label: "Token" },
  { id: "bidaddress", numeric: true, disablePadding: false, label: "Date" },
  { id: "tokendate", numeric: true, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 76%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onChangeterm: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
const customStyle = makeStyles(customInputStyle);

export default function EnhancedTable() {
  const classes = useStyles();
  const customStyles = customStyle();
  const history = useHistory();
  const classesSearch = useToolbarStyles();
  const [selected, setSelected] = React.useState([]);
  const [userDetails, setUser] = useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [searchid, setSearchid] = useState(0);
  const [pageSize, setPageSize] = useState(15);

  async function editR(id) {
    // const web3 = new Web3(window.ethereum);
    // let accounts = await web3.eth.requestAccounts();

    // if (accounts != config.adminaddress) {
    //   toast.error("connect your valid account", toasterOption);
    //   return false;
    // }

    if (id != "") {
      history.push("/Planedit/" + id);
    }
  }

  function lock() {}
  const handleChange = (event) => {
    const { name, value } = event.target;
    const self = this;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setSearchQuery(value);
    setTypingTimeout(
      setTimeout(function () {
        getAll({ search: value });
      }, 1000)
    );
  };

  const handleRequestSort = (event, property) => {};

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    getAll();
  }, []);

  const getAll = async (search = {}) => {
    var test = await getAdminCommision();
    console.log(test, "tetrw");
    if (test) {
      test.result.map((ele, i) => {
        ele.SSno = i + 1;
      });
      setUser(test.result);
    }
  };

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 8) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  const rows1 = userDetails;

  const columns = [
    // { field: 'planId', headerName: 'ID', width: 90 },
    {
      field: "SSno",
      headerName: "S.No.",
      width: 80,
      filterable: false,
      // renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
    },
    {
      field: "_id",
      headerName: "User ID",
      width: 80,
      type: "number",
      headerAlign: "left",
      align: "left",
      editable: false,
      valueGetter: (params) => `${params.row._id}`,
    },
    {
      field: "userName",
      headerName: "Name",
      width: 160,
      // type: "number",
      headerAlign: "left",
      align: "left",
      editable: false,
      valueGetter: (params) =>
        `${params.row.userName ? params.row.userName : "-"}`,
    },
    {
      field: "adminCommision",
      headerName: "Admin Commision",
      width: 160,
      // type: "number",
      headerAlign: "left",
      align: "left",
      editable: false,
      valueGetter: (params) =>
        `${params.row.totalCommision ? params.row.totalCommision : "-"}`,
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{
            fileName: `ProfileDetails${moment(Date.now()).format(
              "YYYYMMDDhhmmss"
            )}`,
            // utf8WithBom: true,
          }}
          rows={rows1}
          columns={columns}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4
                className={classes.cardTitleWhite}
                style={{ marginBottom: "5px" }}
              >
                Profile Details
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ height: 900, width: "100%" }}>
                    <DataGrid
                      rows={rows1}
                      columns={columns}
                      // pageSize={15}
                      getRowId={(row) => row._id}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[15, 20, 30]}
                      pagination
                      // rowsPerPageOptions={[5]}
                      checkboxSelection={false}
                      disableSelectionOnClick
                      disableColumnSelector
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
