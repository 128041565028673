// module.exports = {
//   // baseUrl : "https://bosticapi.alwin.io",  //demo
//   baseUrl: "https://api.doublerich.biz", //Live
//   network: "56",
//   // frontUrl: "https://bostic.alwin.io/",  //demo
//   frontUrl:  "https://www.doublerich.biz/", //Live
//   // API_URL : "https://bosticapi.alwin.io" ,  //demo
//   API_URL: "https://api.doublerich.biz", //Live
//   adminaddress: "0x1f5c946E71710fEb6762393797308d8344F11ed2",
//   Duecoin_contract: "0x7b2de3fa70d332b0392a15807ddb57f84b613a06",
//   bostic_contract: "0xC39c668B756A756e8683a48b56e4C8E855d527d0",
// };

module.exports = {
  baseUrl : "https://bosticapi.wearedev.team",  //demo
  network:"97",
  frontUrl: "https://bostic-controls.pages.dev/",  //demo
  API_URL : "https://bosticapi.wearedev.team" ,  //demo
  adminaddress: "0x26F9F0c809779feA862B28a106Fd883fac4a3Fb7", // 0x553AA0d89E40D48f4f670d0303552132C0bc0ee3
  Duecoin_contract:"0xab1a806447c88D75CD8F31a7A2762a80E808cf99",
  bostic_contract:"0xEC40D8A1EF33BeF7446a6A513dA4E323DDA7292F"
}
