import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Icon from "@material-ui/core/Icon";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { getmaxuser, Lockplandata, getPackages } from "../../actions/admin";
import Search from "@material-ui/icons/Search";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Link, useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { toastAlert } from "../../lib/toastAlert";
import CardHeader from "../../components/Card/CardHeader.js";
import * as moment from "moment";
import { TextField } from "@material-ui/core";
import Web3 from "web3";
import ReactDatatable from "@ashvin27/react-datatable";
import config from "../../actions/config";
import { CSVLink, CSVDownload } from "react-csv";
// function createData(FirstName, lastName, Email, phoneNo, accountType) {
//   return { FirstName, lastName, Email, phoneNo, accountType };
// }
import { toast } from "react-toastify";
const rows = [
  // getData('')
  //   createData('firstName', 'lastName', 'Email', 'phoneNo', 'accountType')
  //   createData('Donut', 452, 25.0, 51, 4.9),
];

const headCells = [
  { id: "category", numeric: false, disablePadding: true, label: "Token" },
  { id: "bidaddress", numeric: true, disablePadding: false, label: "Date" },
  { id: "tokendate", numeric: true, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 76%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onChangeterm: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
const customStyle = makeStyles(customInputStyle);

export default function EnhancedTable() {
  const classes = useStyles();
  const customStyles = customStyle();
  const history = useHistory();
  const classesSearch = useToolbarStyles();
  const [data, setData] = React.useState(0);
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [userDetails, setUser] = useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [responsive, setresponsive] = useState(true);
  const [searchid, setSearchid] = useState(0);
  const [allDwnlUser, setAllDownUser] = useState([]);
  const [PlanName, setPlanName] = useState({
    plan1: 0,
    plan2: 0,
    plan3: 0,
    plan4: 0,
    plan5: 0,
    plan6: 0,
    plan7: 0,
  });
  const [pageSize, setPageSize] = useState(15);

  async function editR(id) {
    // const web3 = new Web3(window.ethereum);
    // let accounts = await web3.eth.requestAccounts();

    // if (accounts != config.adminaddress) {
    //   toast.error("connect your valid account", toasterOption);
    //   return false;
    // }

    if (id != "") {
      history.push("/Planedit/" + id);
    }
  }

  function lock() {}
  const handleChange = (event) => {
    const { name, value } = event.target;
    const self = this;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setSearchQuery(value);
    setTypingTimeout(
      setTimeout(function () {
        getAll({ search: value });
      }, 1000)
    );
  };

  const handleRequestSort = (event, property) => {};

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    getAll();
  }, [searchid]);

  const getAll = async (search = {}) => {
    // let filterData = search;
    // if (filterData.search == "") {
    let payload = {
      referealAmtBos: searchid,
    };
    // }
    var test = await getmaxuser(payload);
    // gettokenlist(filterData);
    if (test) {
      test.result.map((ele, i) => {
        ele.SSno = i + 1;
        ele.updatedAt = moment(ele.updatedAt).format("DD MMM YYYY hh:mm:ss A");
        ele.bidTime = moment(ele.bidTime).format("DD MMM YYYY hh:mm:ss A");
        ele.createdAt = moment(ele.createdAt).format("DD MMM YYYY hh:mm:ss A");
        if (ele.autoRenewal == true) {
          ele.autoRenewal = "On";
        } else if (ele.autoRenewal == false) {
          ele.autoRenewal = "Off";
        }
      });
      setUser(test.result);
      const ttt = capitalizeObjectKeys(test.result);
      setAllDownUser(test.result);
    }
  };

  const capitalizeObjectKeys = (obj) => {
    if (!obj) return;
    if (Array.isArray(obj)) {
      return obj.map((each) => capitalizeObjectKeys(each));
    }
    const results = {};
    Object.keys(obj).forEach((key) => {
      const newKey = key.charAt(0).toUpperCase() + key.slice(1);
      const objVal =
        typeof obj[key] === "object"
          ? capitalizeObjectKeys(obj[key])
          : obj[key];
      results[newKey] = objVal;
    });
    return results;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    getPackageNames();
  }, []);

  const getPackageNames = async () => {
    const data = await getPackages();
    setPlanName({
      ...PlanName,
      plan1: data && data.result && data.result.result[0].planAmount,
      plan2: data && data.result && data.result.result[1].planAmount,
      plan3: data && data.result && data.result.result[2].planAmount,
      plan4: data && data.result && data.result.result[3].planAmount,
      plan5: data && data.result && data.result.result[4].planAmount,
      plan6: data && data.result && data.result.result[5].planAmount,
      plan7: data && data.result && data.result.result[6].planAmount,
    });
  };

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 8) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  const rows1 = userDetails;

  const columns = [
    // { field: 'planId', headerName: 'ID', width: 90 },
    {
      field: "SSno",
      headerName: "S.No.",
      width: 60,
      filterable: false,
      // renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
    },
    {
      field: "userID",
      headerName: "User ID",
      width: 60,
      type: "number",
      headerAlign: "left",
      align: "left",
      editable: false,
      valueGetter: (params) => `${params.row.userID}`,
    },
    {
      field: "userName",
      headerName: "Name",
      width: 140,
      // type: "number",
      headerAlign: "left",
      align: "left",
      editable: false,
      valueGetter: (params) =>
        `${params.row.userName ? params.row.userName : "-"}`,
    },
    {
      field: "userPhone",
      headerName: "Phone",
      width: 140,
      type: "number",
      headerAlign: "left",
      align: "left",
      editable: false,
      valueGetter: (params) =>
        `${params.row.userPhone ? params.row.userPhone : "-"}`,
    },
    {
      field: "useremail",
      headerName: "Email",
      width: 160,
      // type: 'number',
      headerAlign: "left",
      align: "left",
      editable: false,
      valueGetter: (params) =>
        `${params.row.useremail ? params.row.useremail : "-"}`,
    },
    {
      field: "address",
      headerName: "Address",
      width: 160,
      // type: 'number',
      headerAlign: "left",
      align: "left",
      editable: false,
      renderCell: (record) => (
        <div>
          <>{halfAddrShow(record.row.address)}</>
        </div>
      ),
    },
    {
      field: "autoRenewal",
      headerName: "Auto Renewal",
      width: 160,
      // type: 'number',
      headerAlign: "left",
      align: "left",
      editable: false,
      // renderCell: (record) => (
      //   <div>
      //     <>{record.row.autoRenewal == true ? "On" : "Off"}</>
      //   </div>
      // ),
      // valueFormatter: ({ value }) => `${value == true ? "On" : "Off"}`,
    },
    {
      field: "originalReferrer",
      headerName: "Referral Address",
      width: 160,
      // type: 'number',
      headerAlign: "left",
      align: "left",
      editable: false,
      renderCell: (record) => (
        <div>
          <>{halfAddrShow(record.row.originalReferrer)}</>
        </div>
      ),
    },
    {
      field: "originalReferrerID",
      headerName: "Referral ID",
      width: 100,
      type: "number",
      headerAlign: "left",
      align: "left",
      editable: false,
    },
    {
      field: "originalReferrerName",
      headerName: "Referral Name",
      width: 160,
      // type: 'number',
      headerAlign: "left",
      align: "left",
      editable: false,
      valueGetter: (params) =>
        `${
          params.row.originalReferrerName
            ? params.row.originalReferrerName
            : "-"
        }`,
    },
    {
      field: "originalReferrerPhone",
      headerName: "Referral Phone",
      width: 160,
      // type: 'number',
      headerAlign: "left",
      align: "left",
      editable: false,
      valueGetter: (params) =>
        `${
          params.row.originalReferrerPhone
            ? params.row.originalReferrerPhone
            : "-"
        }`,
    },
    {
      field: "plan",
      headerName: "Plan",
      width: 80,
      type: "number",
      headerAlign: "left",
      align: "left",
      editable: false,
      renderCell: (record) => {
        return (
          <>
            {record.row.plan == 1
              ? PlanName.plan1
              : record.row.plan == 2
              ? PlanName.plan2
              : record.row.plan == 3
              ? PlanName.plan3
              : record.row.plan == 4
              ? PlanName.plan4
              : record.row.plan == 5
              ? PlanName.plan5
              : record.row.plan == 6
              ? PlanName.plan6
              : PlanName.plan7}
          </>
        );
      },
    },
    {
      field: "retopupTimes",
      headerName: "Retopup Count",
      width: 140,
      type: "number",
      headerAlign: "left",
      align: "left",
      editable: false,
      // renderCell: (record) => {
      //   return (
      //     <Fragment>{record.status == 1 ? "Approved" : "Rejected"}</Fragment>
      //   );
      // },
    },
    {
      field: "referealAmtBos",
      headerName: "Bostic ($)",
      width: 100,
      type: "number",
      headerAlign: "left",
      align: "left",
      editable: false,
      renderCell: (record) => {
        return (
          <Fragment>
            {record.row.referealAmtBos - record.row.withdrawAmtBos}
          </Fragment>
        );
      },
    },
    {
      field: "accumulationAmountDue",
      headerName: "Accumulation Amount",
      width: 180,
      type: "number",
      headerAlign: "left",
      align: "left",
      editable: false,
      // renderCell: (record) => {
      //   return (
      //     <Fragment>{record.status == 1 ? "Approved" : "Rejected"}</Fragment>
      //   );
      // },
    },
    {
      field: "referealAmtDue",
      headerName: "Due Coin",
      width: 100,
      type: "number",
      headerAlign: "left",
      align: "left",
      editable: false,
      // renderCell: (record) => {
      //   return (
      //     <Fragment>{record.status == 1 ? "Approved" : "Rejected"}</Fragment>
      //   );
      // },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{
            fileName: `UsersList${moment(Date.now()).format("YYYYMMDDhhmmss")}`,
            utf8WithBom: true,
          }}
          rows={rows1}
          columns={columns}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4
                className={classes.cardTitleWhite}
                style={{ marginBottom: "5px" }}
              >
                Users List
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ height: 900, width: "100%" }}>
                    <DataGrid
                      rows={rows1}
                      columns={columns}
                      // pageSize={15}
                      getRowId={(row) => row._id}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[15, 20, 30]}
                      pagination
                      // rowsPerPageOptions={[5]}
                      checkboxSelection={false}
                      disableSelectionOnClick
                      disableColumnSelector
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CSVLink
              filename={
                "AllUsersList" + moment(Date.now()).format("YYYYMMDDhhmmss")
              }
              data={allDwnlUser}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  float: "right",
                  marginRight: "18px",
                  marginBottom: "20px",
                }}
                className={classes.button}
                endIcon={<Icon>download</Icon>}
              >
                All Users Details
              </Button>
            </CSVLink>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
