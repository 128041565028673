import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";
import Web3 from "web3";
import "react-phone-input-2/lib/style.css";
import config from "../../actions/config";

import {
  getsettingsData,
  UpdateplanPercetage,
  getBidPercentage,
} from "../../actions/admin.js";
import { toastAlert } from "../../lib/toastAlert.js";
import isEmpty from "../../lib/isEmpty.js";

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    // getadminUsers,
    textDecoration: "none",
  },
};

// toaster config
toast.configure();

const initialFormValue = {
  PlanPercentage: "",
};

const useStyles = makeStyles(styles);

const customStyle = makeStyles(customInputStyle);

export default function UserProfile() {
  const customStyles = customStyle();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const [id, setId] = useState("");
  const [bidPer, setBidPer] = useState("");
  const [adminnameset, setAdminName] = useState("");
  const [tomarDate, settomarDate] = useState();
  const [todayDate, setTodayDate] = useState();
  const [ie, seti] = useState(0);
  const [ide,setIde] = useState("")
  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log(name, value, "EEEEEEEEEee");
    setBidPer(value);
    setValidateError({});
    let formDatass = { ...formData, ...{ [name]: value } };
    setFormData(formDatass);
  };

  const { PlanPercentage } = formData;

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // const web3 = new Web3(window.ethereum);
    // let accounts = await web3.eth.requestAccounts();

    // if (accounts != config.adminaddress) {
    //   toast.error("Connect your valid account", toasterOption);
    //   return false;
    // }

    let reqData = {
      // PlanPercentage: adminnameset,
      id: ide,
      bidper: bidPer,
    };

    const { status, message, error, result } = await UpdateplanPercetage(
      reqData
    );

    if (status) {
      toastAlert("success", message, "Admin");
      window.location.reload()
    } else if (error) {
      setValidateError(error);
    }
  };
  const getadmindata = async () => {
    var { result } = await getsettingsData();


    if (result) {
      setAdminName(result[0].Planpercentage);
      setId(result[0]._id);
    }
  };
  
  const getBidingPercentage = async () => {
    const data = await getBidPercentage();

    setBidPer(data.result[0]?.percentage);
    setIde(data.result[0]?._id)
    var time2 = new Date(data.result[0]?.createdAt).getTime();
    var time1 = new Date().getTime();
    var diff = Math.floor((time2 - time1) / (24 * 3600 * 1000));

    seti(diff);
  };
  useEffect(() => {
    getadmindata();
  }, []);

  useEffect(() => {
    getBidingPercentage();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite} style={{marginBottom:'5px'}}>Today’s Bidding Percentage</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Today’s Bidding Percentage (%)"
                      onChange={(e) => onChange(e)}
                      name="bidPer"
                      value={bidPer}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />

                    {validateError.AdminName && (
                      <span style={{ color: "red" }}>
                        {validateError.AdminName}
                      </span>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button
                  // disabled={ie >= -1 ? true : false}
                  color="primary"
                  type="submit"
                >
                  Update
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
